import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import FsLightbox from "fslightbox-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Hero(props) {
  const [toggler, setToggler] = useState(false)
  const [fullHeight, setFullHeight] = useState(false)

  let hotspot = props.data.hero_bg.hotspot
  if (!hotspot) {
    hotspot = {
      x: 0.5,
      y: 0.5,
    }
  }

  return (
    <BackgroundImage
      className="bg-img-hero-center"
      fluid={props.data.hero_bg.asset.fluid}
      style={{
        backgroundPositionX: `${hotspot.x * 100}%`,
        backgroundPositionY: `${hotspot.y * 100}%`,
      }}
    >
      <div
        className={
          "container space-2 space-lg-5 " + (fullHeight ? "min-vh-md-100" : "")
        }
      >
        <div className="mb-4 text-center">
          <h2 className="h1 text-white ">{props.data.hero_title}</h2>
          <p className="text-white">{props.data.hero_subtitle}</p>
          {props.data.hero_video && (
            <FsLightbox
              toggler={toggler}
              sources={[props.data.hero_video]}
              onOpen={fsLightbox => {
                setFullHeight(true)
              }}
              onClose={fsLightbox => {
                setFullHeight(false)
              }}
            />
          )}
          {props.data.hero_video && (
            <button
              className="display-1 hero-play"
              onClick={() => setToggler(!toggler)}
            >
              <FontAwesomeIcon
                icon={["far", "play-circle"]}
                className="text-white"
              />
            </button>
          )}
          {!props.data.hero_video && <div style={{ height: "100px" }}></div>}
        </div>
      </div>
    </BackgroundImage>
  )
}
