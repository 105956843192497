import React, { useState } from "react"
import ResponsiveEmbed from "react-responsive-embed"
import FsLightbox from "fslightbox-react"
import Img from "gatsby-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default props => {
  const [lb, setLb] = useState({
    toggler: false,
    sources: [],
    customSources: null,
    slide: -1,
  })

  function openLightboxOnSlide(number) {
    const slide = urls[number]
    let sources = null
    let customSources = null
    if (slide.custom) {
      customSources = [
        <div style={{ width: "80vw" }}>
          <ResponsiveEmbed src={slide.url} ratio="4:4" />
        </div>,
      ]
    } else sources = [slide.url]
    if (slide.type !== "text") {
      setLb({
        toggler: !lb.toggler,
        customSources: customSources,
        sources: sources,
        slide: number,
      })
    } else {
      window.open(slide.url, "_blank")
    }
  }

  const urls = props.data.content.map((item, key) => {
    const press = {
      custom: false,
      url: null,
      type: null,
    }
    if (item._type === "press_video") {
      press.url = item.video_url
      press.type = "video"
    } else if (item.__typename === "SanityPressAudio") {
      press.custom = true
      press.url = item.audio_url
      press.type = "audio"
    } else {
      press.custom = true
      press.url = item.link
      press.type = "text"
    }
    return press
  })

  return (
    <div className="container space-2">
      <h1>{lb.toggler}</h1>
      {/* <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span className="d-block small font-weight-bold text-cap mb-2">
          Title
        </span>
        <h2>Subtitle</h2>
      </div> */}
      <FsLightbox
        toggler={lb.toggler}
        sources={lb.sources}
        customSources={lb.customSources}
        key={lb.slide}
      />

      <div className="row">
        {props.data.content.map((item, key) => (
          <div className="col-md-4 col-sm-6 pb-4" key={key}>
            <div
              className="card h-100"
              onClick={() => openLightboxOnSlide(key)}
              onKeyDown={() => openLightboxOnSlide(key)}
              role="button"
              tabIndex={0}
            >
              <Img
                className="card-img-top video-card"
                fluid={item.picture.asset.fluid}
                alt={item.title}
              />

              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
