import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Hero from "../components/hero"
import PressGallery from "../components/press_gallery"
import { Helmet } from "react-helmet"

export default data => (
  <Layout>
    <Helmet title={`Rajiv Satyal - ${data.data.sanityPages.title}`} />
    <Hero data={data.data.sanityPages} />
    <PressGallery data={data.data.sanityPages} />
  </Layout>
)

export const query = graphql`
  query PressQuery {
    sanityPages(slug: { current: { eq: "press" } }) {
      id
      hero_subtitle
      hero_title
      hero_video
      title
      content {
        ... on SanityPressAudio {
          title
          audio_url
          picture {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
        }
        ... on SanityPressText {
          title
          link
          picture {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
        }
        ... on SanityPressVideo {
          _key
          _type
          title
          video_url
          picture {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
        }
      }
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
    }
  }
`
